export const lobSortOrder = {
  internet: 0,
  voice: 1,
  tv: 2,
  smartoffice: 3
}

export const lobTitles = {
  internet: 'Business Internet Additional Services',
  voice: 'Business Voice',
  tv: 'Business Video',
  smartoffice: 'SmartOffice'
}

export const additionalLobTitles = {
  internet: 'Additional Internet Services',
  voice: 'Additional Voice Services',
  tv: 'Additional Video Services',
  smartoffice: 'Additional SmartOffice Services'
}

export const NO_NAME_PRODUCT = 'Unrecognized Product'

export const LOB_SUB_TOTAL  = 'subtotal'

export const subTotalItem = {
  id: LOB_SUB_TOTAL,
  prices: { mrc: { price: 0 } },
  name: ''
}

export const lineOfServiceLobTitles = {
  'Business Internet Additional Services': 'Business Internet Additional Services Sub Total',
  'Business Voice': 'Business Voice Sub Total',
  'Business Video': 'Business Video Sub Total'
}
