import {
  voiceAddOnFlags,
  voiceLineAddOnConfigurations,
  voiceLineItemTypes,
  voiceItemIds,
  configMoveStatusIds,
  configTypes
} from '../constants/configuration'
import { cartLineTypes } from '../constants/cart'
import { decimals } from './math'
import { parseProductDetailsModel } from './order'
import {
  getType,
  separateVoiceLines,
  getSelectedQuantity,
  sortLinesOfBusiness
} from './configuration'
import { lobTitles } from '../constants/orderservices'
import { errors } from '../constants/content'

// Groups Cart config items by LOB
export function parseCartSummary({ cartItems, ...cart }) {
  // find the correct ancillary price & update confItem with name & prices
  function mapConfigAncillaryItem(configItem) {
    const hasAncillaryOptions = !!configItem?.ancillaryPrices?.length

    if (hasAncillaryOptions && configItem.priceReferenceId) {
      const ancillaryPriceItem = configItem?.ancillaryPrices.find(
        ancillary => ancillary.priceReferenceId === configItem.priceReferenceId
      )

      return {
        ...configItem,
        ...ancillaryPriceItem
      }
    }

    return configItem
  }

  // Adds shoppingType to each item (Example: "addOns")
  function mapLineType(types) {
    return Object.entries(types).reduce(
      (lines, [shoppingType, line]) => ({
        ...lines,
        [shoppingType]: (line || []).map(item => ({
          ...item,
          shoppingType,
          type: getType(item, shoppingType),
          configurationItems: item.configurationItems?.map(configItem => {
            const updatedConfigItem = mapConfigAncillaryItem(configItem)
            return {
              ...updatedConfigItem,
              shoppingType,
              lineItemType: item.type
            }
          })
        }))
      }),
      {}
    )
  }

  const updatedCart = {
    ...cart,
    offer: {
      ...cart.offer,
      isSelected: true,
      unitPrice: cart.offer.price,
      monthlyFinalPrice: cart.offer.price,
      oneTimeFinalPrice: 0,
      quantityIncludedInPackage: 0
    },
    linesOfBusiness: cartItems.reduce(
      (lobs, lob) => ({
        ...lobs,
        [lob.lineOfBusiness]: {
          ...lob,
          hasItems: !!Object.values(lob.items).flat().length,
          items: mapLineType(lob.items)
        }
      }),
      {}
    )
  }

  const { changedCartItems, ...categories } = getCartSummaryCategories(
    updatedCart
  )

  return {
    ...updatedCart,
    changedCartItems,
    categories
  }
}

export function parseCartDetails(cart) {
  const productModel = parseProductDetailsModel(cart)
  const productDetails = Object.values(productModel.productDetails).reduce(
    (details, lob) => ({
      ...details,
      ...(lob.lineOfBusiness && { [lob.lineOfBusiness]: lob.cartItemDetails })
    }),
    {}
  )
  const discounts = Object.entries(productDetails).reduce(
    (discs, [lob, items]) => {
      const [mrcDiscount, nrcDiscount] = items.reduce(
        ([mrc, nrc], item) => [
          mrc + (item.prices.mrc.discount || 0),
          nrc + (item.prices.nrc.discount || 0)
        ],
        [0, 0]
      )

      return mrcDiscount || nrcDiscount
        ? [
            ...discs,
            {
              name: `Discount - ${cartLineTypes[lob]?.title || lob}`,
              isDiscount: true,
              prices: {
                mrc: {
                  price: mrcDiscount
                },
                nrc: {
                  price: nrcDiscount
                }
              }
            }
          ]
        : discs
    },
    []
  )

  return {
    ...productModel,
    productDetails: {
      ...productDetails,
      discounts
    }
  }
}

// Gets cart items and groups them by category
export function getCartSummaryCategories(cart) {
  const { linesOfBusiness } = cart

  if (!linesOfBusiness) {
    return null
  }

  const boltOnMap = (cart.offer.boltOns || []).reduce((boltOns, boltOn) => {
    const selectedBoltOn = boltOn.offers.find(
      offer => offer.id === boltOn.selectedOfferId
    )

    return {
      ...boltOns,
      [boltOn.lineOfBusiness]: {
        ...boltOn,
        ...(selectedBoltOn && {
          offer: {
            ...selectedBoltOn,
            quantity: 1,
            monthlyFinalPrice: boltOn.price,
            monthlyPrice: boltOn.price,
            quantityIncludedInPackage: 0,
            monthlyDiscount: 0,
            oneTimePrice: 0,
            oneTimeDiscount: 0,
            oneTimeFinalPrice: 0,
            isBoltOnOffer: true
          }
        })
      }
    }
  }, {})

  function flattenLineItemConfigItems(lineItems = {}) {
    return Object.values(lineItems)
      .flat()
      .flatMap(item => item?.configurationItems || [])
  }

  function getLineOfBusiness(lineType, boltOns = {}) {
    const isOffer = lineType.key === cartLineTypes.offer.key
    const lob = isOffer ? cart.offer : linesOfBusiness[lineType.key]
    const { install, ...lineItems } = lob?.items || {}
    const boltOn = boltOns[lineType.key]

    const flattenedLineItems = flattenLineItemConfigItems(lineItems).map(
      item => {
        const { existingQuantity = 0 } = item

        return {
          ...item,
          existingQuantity,
          isExistingSelection: item.quantity === existingQuantity
        }
      }
    )
    const flattenedInstallItems = flattenLineItemConfigItems(install)

    return {
      ...lineType,
      ...lob,
      allLineItems: flattenedLineItems,
      selectedLineItems: isOffer
        ? [cart.offer]
        : getSelectedItems(flattenedLineItems, lob, boltOn?.offer),
      selectedInstall: getSelectedItems(flattenedInstallItems, lob)
    }
  }

  function getSelectedItems(items = [], lob = {}, boltOnOffer = {}) {
    const configurationItems = boltOnOffer ? [boltOnOffer, ...items] : items

    return configurationItems.filter(item => {
      const { quantity, type } = item || {}

      if (type && lob.lineOfBusiness === cartLineTypes.voice.key) {
        const { includedLines } = lob.configuredItems
        const includedLineQty = includedLines[type] || 0

        return quantity - includedLineQty > 0
      }

      return item.isSelected || quantity > 0
    })
  }

  const lobs = {
    internet: getLineOfBusiness(cartLineTypes.internet, boltOnMap),
    voice: getLineOfBusiness(cartLineTypes.voice, boltOnMap),
    tv: getLineOfBusiness(cartLineTypes.tv, boltOnMap),
    office: getLineOfBusiness(cartLineTypes.office, boltOnMap)
  }

  // Items that differ from customer's existing services
  const changedCartItems = Object.values(lobs)
    .flatMap(l => l.allLineItems)
    .filter(item => !item.isExistingSelection)

  return {
    offer: getLineOfBusiness(cartLineTypes.offer),
    ...lobs,
    changedCartItems,
    additionalOptions: {
      ...cartLineTypes.additionalOptions,
      selectedLineItems: Object.values(lobs).flatMap(l => l.selectedLineItems),
      selectedInstall: Object.values(lobs).flatMap(l => l.selectedInstall)
    }
  }
}

// Calculates totals for Cart Summary
// Returns [MRC, NRC]
export function calcSummaryTotals(lines) {
  const combinedLOBs = Object.values(lines).reduce(
    (all, lob) => [
      ...all,
      ...(lob.selectedLineItems || []),
      ...(lob.selectedInstall || [])
    ],
    []
  )

  return combinedLOBs.reduce(
    ([mrc, nrc], line) => {
      const quantity = getSelectedQuantity(line) || 1
      return [
        decimals.add(
          mrc,
          line.monthlyFinalPrice * (quantity - line.quantityIncludedInPackage)
        ),
        decimals.add(nrc, line.oneTimeFinalPrice * quantity)
      ]
    },
    [0, 0]
  )
}

export function setTvOutletGroupSequences(additionalOutlets) {
  const expandedOutlets = additionalOutlets
    .filter(outlet => outlet.isSelected)
    .reduce(
      (retObject, item, index) => {
        // for each additional outlets, make a copy and update the group sequence
        const { previousQuantity } = retObject
        for (
          let i = previousQuantity;
          i < item.quantity + previousQuantity;
          i += 1
        ) {
          const groupSequence = 1 + i + index

          retObject.retArray.push({ ...item, groupSequence })
        }
        return { previousQuantity: item.quantity, retArray: retObject.retArray }
      },
      { previousQuantity: 1, retArray: [] }
    )
  return expandedOutlets.retArray
}

export function setTollFreeConfiguration(line) {
  if (
    !line.addOnCartItems.find(item => item.id === voiceItemIds.tollFreeLine)
  ) {
    return line
  }

  const configuredTollFree = line

  configuredTollFree.addOnCartItems = configuredTollFree.addOnCartItems.map(
    configuredItem => {
      if (configuredItem.id !== voiceItemIds.tollFreeLine) {
        return configuredItem
      }
      const {
        ringToNumberLine,
        isPorted,
        phoneNumber,
        nativeName,
        callingAreaDestination,
        currentProvider
      } = configuredItem

      return {
        ...configuredItem,
        configurationItem: {
          tollFreeNumberAspectId: '',
          tollFreeNumber: phoneNumber || nativeName,
          ringToNumberAspectId: '',
          ringToNumber:
            ringToNumberLine?.phoneNumber || ringToNumberLine?.nativeName,
          ringToTnType: ringToNumberLine?.lineType,
          callingAreaAspectId: '',
          callingAreaDestination,
          isPorted,
          currentProviderAspectId: '',
          currentProvider: currentProvider || ''
        }
      }
    }
  )
  return configuredTollFree
}

export function fillTollFreeNumbers(
  line,
  tollFreeLines,
  tollFreeActivationFee
) {
  const filledLine = tollFreeLines.reduce(
    (combinedTollFreeLine, tollFreeLine) => {
      const {
        id,
        priceReferenceId,
        ringToNumberLine,
        phoneNumber,
        nativeName,
        callingAreaDestination,
        isPorted,
        currentProvider,
        movesStatusSelections = []
      } = tollFreeLine
      const isMatchingLine =
        tollFreeLine.ringToNumberLine.lineId === line.lineId
      const isNewTollFreeLine = !tollFreeLine.isExistingNumber

      if (isMatchingLine) {
        const addOnCartItems = [
          ...combinedTollFreeLine.addOnCartItems,
          {
            id,
            priceReferenceId,
            ringToNumberLine,
            phoneNumber,
            nativeName,
            callingAreaDestination,
            isPorted: !!isPorted,
            currentProvider,
            addOnType: voiceLineItemTypes.tollFree,
            quantity: 1,
            ...(!!movesStatusSelections?.length && { movesStatusSelections })
          }
        ]

        if (tollFreeActivationFee && isNewTollFreeLine) {
          addOnCartItems.push({
            id: tollFreeActivationFee.id,
            addOnType: voiceLineItemTypes.tollFreeActivationFee,
            priceReferenceId: tollFreeActivationFee.priceReferenceId,
            oneTimeDiscount: tollFreeActivationFee.oneTimeDiscount,
            oneTimePrice: tollFreeActivationFee.oneTimePrice,
            oneTimeFinalPrice: tollFreeActivationFee.oneTimeFinalPrice,
            quantity: 1,
            isSelected: true
          })
        }

        return {
          ...combinedTollFreeLine,
          addOnCartItems
        }
      }

      return combinedTollFreeLine
    },
    line
  )

  return setTollFreeConfiguration(filledLine)
}

export function fillAddOnCartItems(line, addOnConfigurations, tollFreeLines) {
  const filledAddOnLine = line
  const dirListing = filledAddOnLine.addOnCartItems.find(
    item => item.addOnType === voiceLineItemTypes.directoryListing
  )
  const activationFee = filledAddOnLine.addOnCartItems.find(
    item => item.addOnType === voiceLineItemTypes.activationFee
  )
  filledAddOnLine.addOnCartItems = []

  Object.values(voiceAddOnFlags).forEach(flag => {
    if (filledAddOnLine[flag]) {
      filledAddOnLine.addOnCartItems.push({
        id: addOnConfigurations[voiceLineAddOnConfigurations[flag]].id,
        priceReferenceId:
          addOnConfigurations[voiceLineAddOnConfigurations[flag]]
            .priceReferenceId,
        addOnType: voiceLineAddOnConfigurations[flag],
        name: addOnConfigurations[voiceLineAddOnConfigurations[flag]].name,
        configurationItem: { [flag]: filledAddOnLine[flag] },
        quantity: 1
      })
    }
  })

  if (dirListing) {
    filledAddOnLine.addOnCartItems.push({
      ...dirListing,
      ...addOnConfigurations[voiceLineItemTypes.directoryListing]
    })
  }

  if (activationFee) {
    const activationAddOn =
      addOnConfigurations[voiceLineItemTypes.activationFee]

    filledAddOnLine.addOnCartItems.push({
      id: activationAddOn.id,
      priceReferenceId: activationAddOn.priceReferenceId,
      addOnType: voiceLineItemTypes.activationFee,
      name: activationAddOn.name,
      quantity: 1,
      configurationItem: {
        [voiceLineItemTypes.activationFee]: activationAddOn
      }
    })
  }

  // Check if this line is configured for a toll free line, if found, push it in
  return {
    ...filledAddOnLine,
    ...fillTollFreeNumbers(
      filledAddOnLine,
      tollFreeLines,
      addOnConfigurations[voiceLineItemTypes.tollFreeActivationFee]
    )
  }
}

// Takes in the configured voice lines, add on configuration "templates"
export function setVoiceLineAddOns(configuredVoiceLines, addOnConfigurations) {
  // get the lines separated into primary and additional
  const voiceLines = separateVoiceLines(configuredVoiceLines.voiceLines)
  const { tollFree } = configuredVoiceLines

  return {
    primaryLine: fillAddOnCartItems(
      voiceLines.primaryLine,
      addOnConfigurations,
      tollFree
    ),
    additionalLines: voiceLines.additionalLines.map(additionalLine =>
      fillAddOnCartItems(additionalLine, addOnConfigurations, tollFree)
    )
  }
}

export function getUnSelectedMoveLobs(cartLobs, partialDisconnectLobs) {
  const unSelectedLobs = Object.entries(cartLobs).reduce((all, [lob, val]) => {
    const isSelected =
      val.movesStatusSelections?.length > 0 &&
      val.movesStatusSelections.some(status => status.isSelected)
    if (val.movesStatusSelections?.length > 0 && !isSelected) {
      return [...all, lob]
    }
    return all
  }, [])

  const unSelectedDateLobsList = Object.entries(cartLobs).reduce(
    (all, [lob, val]) => {
      const selectedStatus = val.movesStatusSelections?.find(
        status =>
          status.isSelected &&
          status?.id === configMoveStatusIds.tempOverlapOfService
      )

      if (!selectedStatus) {
        return all
      }

      if (selectedStatus) {
        if (!selectedStatus.disconnectDate) {
          return [...all, lob]
        }
      }

      return all
    },
    []
  )

  const unSelectedPartialDisconnectLobs = partialDisconnectLobs.reduce(
    (all, { lineOfBusiness, statuses, telephoneGrid = null }) => {
      if (lineOfBusiness === configTypes.voice) {
        const hasAllLinesSelected = telephoneGrid.every(item =>
          item.statuses.map(status => status.isSelected).includes(true)
        )
        if (!hasAllLinesSelected) {
          return [...all, lineOfBusiness]
        }
        return all
      }
      const isSelected = statuses.some(status => status.isSelected)
      if (!isSelected) {
        return [...all, lineOfBusiness]
      }
      return all
    },
    []
  )

  const unSelectedLobList = [
    ...unSelectedLobs,
    ...unSelectedPartialDisconnectLobs
  ]

  return [
    sortLinesOfBusiness(unSelectedLobList),
    sortLinesOfBusiness(unSelectedDateLobsList)
  ]
}

export function getMoveValidationError(unSelectedList, dateList) {
  const getLobListContent = list => list.map(lob => ` <b>${lobTitles[lob]}</b>`)

  if (dateList.length === 0) {
    return `${errors.configMoveStatusMessage} ${getLobListContent(
      unSelectedList
    )}`
  }

  if (unSelectedList.length === 0) {
    return `${errors.configtempOverLapDateMessage} ${getLobListContent(
      dateList
    )}`
  }

  return `${errors.configMoveStatusMessage} ${getLobListContent(
    unSelectedList
  )} <br/><br/>${errors.configtempOverLapDateMessage} <br/>${getLobListContent(
    dateList
  )}`
}

export function checkScrollbar (checkScorllBarRef) {
  const scrollCheck = checkScorllBarRef.current
  if (scrollCheck) {
    const hasVerticalScrollbar =
      scrollCheck.scrollHeight > scrollCheck.clientHeight
    return hasVerticalScrollbar
  }
  return false
}
