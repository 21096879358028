export const cartTypes = {
  summary: 'summary',
  details: 'details'
}

export const cartLineTypes = {
  offer: {
    key: 'offer',
    title: null
  },
  internet: {
    key: 'internet',
    title: 'BUSINESS INTERNET ADDITIONAL SERVICES'
  },
  voice: {
    key: 'voice',
    title: 'BUSINESS VOICE'
  },
  tv: {
    key: 'tv',
    title: 'BUSINESS VIDEO'
  },
  office: {
    key: 'smartoffice',
    title: 'SmartOffice'
  },
  discounts: {
    key: 'discounts',
    title: 'Discounts'
  },
  additionalOptions: {
    key: 'additionalOptions',
    title: 'Additional Options'
  },
  install: {
    key: 'install',
    title: 'install'
  }
}

export const cartSubTotalTitles = {
  'BUSINESS INTERNET ADDITIONAL SERVICES': 'BUSINESS INTERNET ADDITIONAL SERVICES SUB TOTAL',
  'BUSINESS VOICE': 'BUSINESS VOICE SUB TOTAL',
  'BUSINESS VIDEO': 'BUSINESS VIDEO SUB TOTAL'
}

export const ESTIMATED = 'Estimated'
