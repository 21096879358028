import { takeLatest, put, call, select, take } from 'redux-saga/effects'
import * as actions from '../actions'
import { validateAll } from './validations'
import { parseCartSummary, parseCartDetails } from '../utils/cart'
import { parseProductDetailsModel } from '../utils/order'
import {
  getCartSummary,
  saveCartSummary,
  getCartDetails
} from '../services/cart'
import {
  configurationParser,
  sortLinesOfBusiness,
  checkSmartOfficeInCartItems
} from '../utils/configuration'
import { saveCartFieldName } from '../constants/form'
import { orderRouteIds } from '../constants/navigation'
import { selectActiveForm } from '../selectors/form'
import {
  selectConfiguredCart,
  selectIsFdxConfigReverted,
  selectShowDwellTypeError,
  selectShowFdxConfigRevertError
} from '../selectors/cart'
import { appTypes, workflowStepIds } from '../constants/session'
import { errors, warning } from '../constants/content'
import { configTypes, staticIpMove } from '../constants/configuration'
import {
  selectCBMStandaloneOrderFlowEnabled,
  selectHasMoveDetailsAvailable,
  selectIsMoveSelected
} from '../selectors/order'
import { selectSourceSystemType } from '../selectors/session'

export function* getCartSummarySaga({ payload }) {
  const { offerId } = payload
  try {
    const {
      workflowStepId,
      sessionId,
      opportunityId,
      fxbuyflowSessionId,
      appType
    } = yield select(state => state.session)
    const cbmStandaloneOrderFlowEnabled = yield select(
      selectCBMStandaloneOrderFlowEnabled
    )

    yield put(actions.togglePageLoading())
    yield put(actions.setCartSummary(undefined))
    yield put(actions.resetOfferConfiguration())

    /* TODO: Need to wait until getmoves call is done check wid BE if they can send in customer call */
    const moveDetailsAvailable = yield select(selectHasMoveDetailsAvailable)
    const { isMovesEnabled, isLegacyVideoRestrictionEnabled } = yield select(
      ({ order }) => order
    )
    if (!moveDetailsAvailable && isMovesEnabled) {
      yield take(actions.UPDATE_ORDER)
    }
    let isMoveSelected = yield select(selectIsMoveSelected)
    if (workflowStepId >= workflowStepIds.ORDER_REJECTED && isMovesEnabled) {
      isMoveSelected = true
    }

    const cart = yield call(getCartSummary, {
      sessionId,
      opportunityId,
      offerId,
      fxbuyflowSessionId,
      cbmStandaloneOrderFlowEnabled
    })

    // Cart configuration should always match Cart Summary, only difference is how they are parsed
    yield put(
      actions.replaceConfiguration(configurationParser(cart, isMoveSelected))
    )

    if (isLegacyVideoRestrictionEnabled) {
      const config = configurationParser(cart, isMoveSelected)
      const legacyVideoConfiguration = config?.tv
      yield put(actions.updateOrder({ legacyVideoConfiguration }))
    }

    yield put(actions.setCartSummary(parseCartSummary(cart)))
    yield put(actions.setSelectedOffer(cart.offer))

    const [firstLineOfBusiness] = sortLinesOfBusiness(
      cart.offer.lineOfBusinesses
    )
    yield put(
      actions.setConfigAccordionSections({ [firstLineOfBusiness]: true })
    )

    const hasSmartOffice = checkSmartOfficeInCartItems(cart)
    if (hasSmartOffice && appType === appTypes.NC) {
      yield put(actions.getSmartOfficePermitBanner())
    }

    yield call(setCbmStandaloneOrderFlow, cart)

    if (workflowStepId > workflowStepIds.PENDING_CONFIGURATION) {
      yield put(actions.getCurrentWorkflowStep())
    }
  } catch (error) {
    yield put(actions.setCartSummary(null))
    if (error.message === errors.selApiFailureErrorMsg) {
      yield put(
        actions.setFormErrorAlert(errors.selApiFailurePopUp, {
          message: errors.selApiFailure,
          onOkAction: [actions.SEL_API_FAILURE]
        }
        )
      )
    }
  } finally {
    yield put(actions.togglePageLoading(false))
  }
}

export function* getCartDetailsSaga() {
  try {
    const { opportunityId } = yield select(({ session }) => session)
    const cart = yield call(getCartDetails, opportunityId)

    yield put(actions.setCartQuote(parseProductDetailsModel(cart)))
    yield put(actions.setCartDetails(parseCartDetails(cart)))
  } catch (error) {
    yield put(actions.setCartDetails({}))
  }
}

function* setCbmStandaloneOrderFlow(cart) {
  yield put(
    actions.updateOrder({
      cbmStandaloneOrderFlowEnabled: cart.cbmStandaloneOrderFlowEnabled
    })
  )

  if (cart.cbmStandaloneOrderFlowEnabled) {
    yield put(actions.updateCartCbm(true))
  }
}

export function* saveCartSaga({ payload }) {
  const {
    isContinue,
    isVideoDwellTypeErrorShown = false,
    isFdxConfigErrorShown = false
  } = payload || {}
  const form = yield select(selectActiveForm)
  const showDwellTypeError = yield select(selectShowDwellTypeError)
  const sourceSystem = yield select(selectSourceSystemType)
  const showFdxConfigRevertError = yield select(selectShowFdxConfigRevertError)
  const isFdxConfigReverted = yield select(selectIsFdxConfigReverted)

  try {
    // Validate all configurations
    if (isContinue) {
      yield* validateAll()
    }

    // Check for valid form (customer email, etc)
    if (isContinue && !form.isValid) {
      return false
    }

    // check if tv and dwell type error not shown
    if (
      isContinue &&
      form.isValid &&
      showDwellTypeError &&
      !isVideoDwellTypeErrorShown
    ) {
      return yield put(
        actions.setFormErrorAlert('dwellTypeError', {
          message: errors.videoDwellType,
          onOkAction: [actions.SAVE_CART_VIDEO_DWELL_TYPE],
          onCancelAction: actions.CANCEL_VIDEO_DWELL_TYPE_ERROR
        })
      )
    }

    if (
      isContinue &&
      form.isValid &&
      showFdxConfigRevertError &&
      !isFdxConfigReverted &&
      !isFdxConfigErrorShown
    ) {
      return yield put(
        actions.setFormErrorAlert('fdxConfigRevertError', {
          message: errors.fdxConfigRevertError,
          onOkAction: [actions.SAVE_CART_FDX_CONFIG],
          onCancelAction: actions.CANCEL_FDX_CONFIG_ERROR
        })
      )
    }

    yield put(actions.togglePageLoading())
    const cart = yield select(selectConfiguredCart)

    const cbmStandaloneOrderFlowEnabled = yield select(
      selectCBMStandaloneOrderFlowEnabled
    )

    const cartDetails = yield call(
      saveCartSummary,
      cart,
      isContinue,
      cbmStandaloneOrderFlowEnabled,
      sourceSystem
    )
    const { workflowStepId } = yield select(state => state.session)

    if (isContinue) {
      if (workflowStepId === workflowStepIds.PENDING_CONFIGURATION) {
        yield put(
          actions.setSessionData({
            workflowStepId: workflowStepIds.PENDING_CONTRACT_ACCEPTANCE
          })
        )
      }
      yield put(actions.setCartDetails(parseCartDetails(cartDetails)))
      yield put(actions.setCartQuote(parseProductDetailsModel(cartDetails)))
      yield put(actions.navigateToNextOrderRoute(orderRouteIds.configure))
      yield put(actions.setOrderMoveComcastTN())
    }
  } catch (error) {
    if (error.message === staticIpMove) {
      return yield put(
        actions.setWarningAlert('staticMoveConfig', warning.staticMoveConfig)
      )
    }
    // Only show modal for save cart API error
    if (error instanceof Error) {
      yield put(
        actions.setConfigError(saveCartFieldName, {
          message: error.message,
          onOkAction: actions.CLEAR_FORM_FIELD_STATUSES
        })
      )
    }
  } finally {
    yield put(actions.togglePageLoading(false))
  }

  return true
}

export function* saveCartVideoDwellTypeSaga() {
  yield put(actions.clearFormError('dwellTypeError', 'configure'))
  yield put(
    actions.saveCart({ isContinue: true, isVideoDwellTypeErrorShown: true })
  )
}

export function* cancelCartVideoDwellTypeSaga() {
  yield put(actions.clearFormError('dwellTypeError', 'configure'))
  yield put(actions.setConfigAccordionSections({ [configTypes.tv]: true }))
}

export function* saveCartFdxConfigSaga() {
  yield put(actions.clearFormError('fdxConfigRevertError', 'configure'))
  yield put(actions.saveCart({ isContinue: true, isFdxConfigErrorShown: true }))
}

export function* cancelFdxConfigErrorSaga() {
  yield put(actions.clearFormError('fdxConfigRevertError', 'configure'))
  yield put(actions.setConfigAccordionSections({ [configTypes.tv]: true }))
}

function* rootSaga() {
  yield takeLatest(actions.GET_CART_SUMMARY, getCartSummarySaga)
  yield takeLatest(actions.GET_CART_DETAILS, getCartDetailsSaga)
  yield takeLatest(actions.SAVE_CART, saveCartSaga)
  yield takeLatest(
    actions.SAVE_CART_VIDEO_DWELL_TYPE,
    saveCartVideoDwellTypeSaga
  )
  yield takeLatest(
    actions.CANCEL_VIDEO_DWELL_TYPE_ERROR,
    cancelCartVideoDwellTypeSaga
  )
  yield takeLatest(actions.SAVE_CART_FDX_CONFIG, saveCartFdxConfigSaga)
  yield takeLatest(actions.CANCEL_FDX_CONFIG_ERROR, cancelFdxConfigErrorSaga)
}

export default rootSaga
