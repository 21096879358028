import {
  takeLatest,
  select,
  put,
  call,
  takeEvery,
  all
} from 'redux-saga/effects'
import * as actions from '../../actions'
import { configurationLineItemSaga } from './configuration'
import {
  getHeaderLookups,
  getNativeRateCenterDetails,
  getPortableRateCenterDetails
} from '../../services/configuration'
import {
  lineItemTypes,
  voiceLineItemTypes,
  voiceRadioFieldNames,
  configTypes,
  VOICE_LINE_API_ERROR,
  PORTED_NUMBER,
  voiceSections,
  voiceItemIds
} from '../../constants/configuration'
import {
  parseRateCenterDetails,
  getPrimaryLine,
  getConfigItem,
  getPortedLinesSuccessMessage,
  mapMoveVoiceLineRateCenters
} from '../../utils/configuration'
import {
  selectConfiguredVoiceLines,
  selectVoiceConfiguredItems,
  selectConfigItemByType,
  selectVoiceLineByNumber,
  selectInitialConfiguredVoiceLines,
  selectVoiceAdditionalInfo,
  selectLineItemByType,
  selectExistingServiceItemById,
  selectNonValidatedMoveVoiceLines
} from '../../selectors/configuration'
import { configFieldNames, crcpError } from '../../constants/form'
import { arrayToObject } from '../../utils/core'
import * as voiceValidations from '../../utils/validations/voice'

const lineItemSagas = {
  [lineItemTypes.configuredItems]: updateVoiceLineSaga
}

export function* setupVoiceLinesSaga() {
  const configuredItems = yield select(selectVoiceConfiguredItems)
  const configuredLines = yield select(selectInitialConfiguredVoiceLines)

  yield put(
    actions.setConfig({
      ...configuredItems,
      configuredLines
    })
  )
}

export function* updateVoiceLineSaga({ payload }) {
  const { item, fieldName } = payload
  const updatedItem = {
    ...item,
    attributes: { ...item.attributes, error: false }
  }

  if (fieldName === 'migratedNumber') {
    return yield put(
      actions.migrateVoiceLine({ ...payload, item: updatedItem })
    )
  }

  if (fieldName === 'attributes') {
    return yield put(
      actions.updateVoiceLineAttribute({ ...payload, item: updatedItem })
    )
  }

  if (voiceRadioFieldNames.includes(fieldName)) {
    const { voiceLines } = yield select(selectConfiguredVoiceLines)
    const selectedLine = voiceLines.find(line => !!line[fieldName])

    if (selectedLine) {
      yield put(actions.setVoiceLine({ ...selectedLine, [fieldName]: false }))
    }
  }

  return yield put(actions.setVoiceLine(updatedItem))
}

export function* updateVoiceLineQuantitySaga({ payload }) {
  const { item, lineItem } = payload

  if (lineItem.type === voiceLineItemTypes.tollFree) {
    const tollFreeFeeActivationFee = yield select(
      selectLineItemByType(voiceLineItemTypes.tollFreeActivationFee)
    )

    if (tollFreeFeeActivationFee) {
      const existingTollFreeLine = yield select(
        selectExistingServiceItemById(
          configTypes.voice,
          voiceItemIds.tollFreeLine
        )
      )
      const newQty = item.quantity - (existingTollFreeLine?.quantity || 0)
      const updatedQty = Math.max(newQty, 0)

      yield put(
        actions.updateConfigItem({
          lineItem: tollFreeFeeActivationFee,
          item: {
            ...getConfigItem(tollFreeFeeActivationFee),
            quantity: updatedQty,
            isSelected: !!updatedQty
          }
        })
      )
    }
  }

  yield put(actions.updateConfigItem(payload))
}

export function* updateVoiceLineAttributesSaga({ payload }) {
  const { item } = payload

  if (
    item.attributes.state &&
    item.attributes.state !== item.attributes.crcState &&
    !item.attributes.allowManualInput
  ) {
    try {
      const { opportunityId, fxbuyflowSessionId } = yield select(
        ({ session }) => session
      )

      yield put(actions.togglePageLoading())
      const rateCenterData = yield call(getNativeRateCenterDetails, {
        opportunityId,
        state: item.attributes.state,
        fxbuyflowSessionId
      })

      const npaRateCenterOptions = parseRateCenterDetails(
        rateCenterData.rateCenterDetails
      )
      const [firstNpa] = Object.keys(npaRateCenterOptions)
      const [firstRateCenter] = npaRateCenterOptions[firstNpa]

      return yield put(
        actions.setVoiceLine({
          ...item,
          npaRateCenterOptions,
          isValidated: true,
          attributes: {
            ...item.attributes,
            crcState: rateCenterData.state,
            allowManualInput: false,
            npa: firstNpa,
            rateCenter: firstRateCenter,
            nxx: ''
          }
        })
      )
    } catch (error) {
      yield put(actions.setFormErrorAlert(crcpError, error.message))

      return yield put(
        actions.setVoiceLine({
          ...item,
          isValidated: false,
          attributes: {
            ...item.attributes,
            allowManualInput: error.message === VOICE_LINE_API_ERROR,
            crcState: item.attributes.state,
            npa: '',
            rateCenter: '',
            nxx: ''
          },
          npaRateCenterOptions: {}
        })
      )
    } finally {
      yield put(actions.togglePageLoading(false))
    }
  }

  return yield put(actions.setVoiceLine(item))
}

export function* setupVoiceAdditionalInfoSaga() {
  const additionalInfo = yield select(selectVoiceAdditionalInfo)

  yield put(
    actions.setConfig({
      lineOfBusiness: configTypes.voice,
      type: lineItemTypes.configuredItems,
      ...additionalInfo
    })
  )
}

export function* updateVoiceDirectoryListingsSaga({ payload }) {
  const { configuredItems } = yield select(
    ({ configuration }) => configuration.voice
  )
  const publishedDirListing = yield select(
    selectConfigItemByType(voiceLineItemTypes.directoryListing)
  )
  const activationFee = yield select(
    selectConfigItemByType(voiceLineItemTypes.activationFee)
  )
  const { configuredLines } = configuredItems
  const primaryLine = getPrimaryLine(configuredLines)

  const addOnObject = arrayToObject(primaryLine.addOnCartItems, 'addOnType')

  return yield put(
    actions.setVoiceLine({
      ...primaryLine,
      addOnCartItems: Object.values({
        ...addOnObject,
        [voiceLineItemTypes.directoryListing]: {
          id: publishedDirListing.id,
          addOnType: voiceLineItemTypes.directoryListing,
          priceReferenceId: publishedDirListing.priceReferenceId,
          ...addOnObject[voiceLineItemTypes.directoryListing],
          configurationItem: payload,
          quantity: 1
        },
        ...(activationFee && {
          [voiceLineItemTypes.activationFee]: {
            id: activationFee.id,
            addOnType: voiceLineItemTypes.activationFee,
            priceReferenceId: activationFee.priceReferenceId,
            configurationItem: activationFee,
            quantity: 1
          }
        })
      })
    })
  )
}

export function* updateVoiceE911NotificationSaga({ payload }) {
  const { configuredItems } = yield select(
    ({ configuration }) => configuration.voice
  )

  yield put(actions.setLastUpdatedVoiceSection(voiceSections.configLines))
  return yield put(
    actions.setConfig({
      ...configuredItems,
      e911Notification: payload
    })
  )
}

export function* updateVoiceProviderDetailsSaga({ payload }) {
  const { configuredItems } = yield select(
    ({ configuration }) => configuration.voice
  )

  return yield put(
    actions.setConfig({
      ...configuredItems,
      providerDetails: payload
    })
  )
}

export function* getVoiceHeaderLookupsSaga({ payload }) {
  if (payload) {
    const { opportunityId, fxbuyflowSessionId } = yield select(
      ({ session }) => session
    )
    const { address } = payload
    const { dlAddress1, dlAddress2, dlCity, dlState, dlZip } = address
    const searchParams = {
      address: {
        streetAddress: `${dlAddress1} ${dlAddress2}`,
        zipCode: dlZip,
        city: dlCity,
        state: dlState
      },
      headerText: payload.headerText || '',
      sicCode: payload.sicCode || '',
      opportunityId,
      fxbuyflowSessionId
    }

    // Set to null so spinner is shown
    yield put(actions.setHeaderLookups(null))
    const lookups = yield call(getHeaderLookups, searchParams)
    yield put(actions.setHeaderLookups(lookups))
  } else {
    yield put(actions.setHeaderLookups([]))
  }
}

export function* migrateVoiceLineSaga({ payload }) {
  const { item } = payload
  // select the line whos number is item.migratedNumber
  if (item.migratedNumber !== PORTED_NUMBER) {
    const duplicateLine = yield select(
      selectVoiceLineByNumber(item.migratedNumber)
    )

    if (duplicateLine) {
      yield put(
        actions.setVoiceLine({
          ...duplicateLine,
          phoneNumber: '',
          migratedNumber: PORTED_NUMBER
        })
      )
    }
  }
  yield put(
    actions.setVoiceLine({
      ...item,
      phoneNumber:
        item.migratedNumber === PORTED_NUMBER ? '' : item.migratedNumber
    })
  )
}

export function* configVoiceItemSaga({ payload }) {
  // Filter out non user updates
  if (payload.sectionId && payload.actionType !== actions.CONFIG_ON_MOUNT) {
    yield put(actions.validatedVoice(false))
    yield put(actions.setLastUpdatedVoiceSection(payload.sectionId))
  }

  yield configurationLineItemSaga(lineItemSagas)({ payload })
}

export function* validateMoveVoiceLinesSaga() {
  const { opportunityId, fxbuyflowSessionId } = yield select(
    ({ session }) => session
  )
  const state = yield select()
  const nonValidatedMoveVoiceLines = selectNonValidatedMoveVoiceLines(state)
  const nonValidatedMoveVoiceLinesList = nonValidatedMoveVoiceLines
    .map(line => line.phoneNumber)
    .filter(Boolean)
  const { voiceLines } = selectConfiguredVoiceLines(state)

  try {
    yield put(actions.togglePageLoading())
    const { rateCenterDetails } = yield call(getPortableRateCenterDetails, {
      opportunityId,
      phoneNumbers: nonValidatedMoveVoiceLinesList,
      fxbuyflowSessionId
    })

    const updatedMoveVoiceLines = mapMoveVoiceLineRateCenters(
      voiceLines,
      rateCenterDetails
    )

    const errorMessage = voiceValidations.validateEligiblePortedLines(
      updatedMoveVoiceLines
    )

    yield all(
      updatedMoveVoiceLines.map(line => put(actions.setVoiceLine(line)))
    )
    if (errorMessage) {
      yield put(actions.setWarningAlert(configFieldNames.voice, errorMessage))
      return errorMessage
    }
    const validatedLines = updatedMoveVoiceLines
      .map(line => !!line.phoneNumber && line)
      .filter(Boolean)

    yield put(
      actions.setSuccessAlert(
        configFieldNames.voice,
        getPortedLinesSuccessMessage(validatedLines)
      )
    )
  } catch (err) {
    console.error('getPortableRateCenterDetails', err)
    yield all(
      nonValidatedMoveVoiceLinesList.map(line =>
        put(
          actions.setVoiceLine({
            ...line,
            isValidated: false,
            isCRC: true,
            attributes: { ...line.attributes, allowManualInput: true }
          })
        )
      )
    )
  } finally {
    yield put(actions.togglePageLoading(false))
  }

  return true
}

function* rootSaga() {
  yield takeLatest(actions.CONFIG_ITEM_VOICE, configVoiceItemSaga)
  yield takeLatest(actions.CONFIG_SETUP_VOICE_LINES, setupVoiceLinesSaga)
  yield takeLatest(
    actions.SETUP_VOICE_ADDITIONAL_INFO,
    setupVoiceAdditionalInfoSaga
  )
  yield takeEvery(
    actions.UPDATE_VOICE_DIRECTORY_LISTINGS,
    updateVoiceDirectoryListingsSaga
  )
  yield takeEvery(
    actions.UPDATE_VOICE_E911_NOTIFICATION,
    updateVoiceE911NotificationSaga
  )
  yield takeLatest(
    actions.UPDATE_VOICE_PROVIDER_DETAILS,
    updateVoiceProviderDetailsSaga
  )
  yield takeLatest(actions.GET_VOICE_HEADER_LOOKUPS, getVoiceHeaderLookupsSaga)
  yield takeLatest(
    actions.UPDATE_VOICE_LINE_ATTRIBUTE,
    updateVoiceLineAttributesSaga
  )
  yield takeLatest(actions.MIGRATE_VOICE_LINE, migrateVoiceLineSaga)
  yield takeLatest(actions.UPDATE_VOICE_LINE_QTY, updateVoiceLineQuantitySaga)
  yield takeLatest(
    actions.VALIDATE_MOVE_VOICE_LINES,
    validateMoveVoiceLinesSaga
  )
}

export default rootSaga
