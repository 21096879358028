import { takeLatest, select, put, call, all, take } from 'redux-saga/effects'
import * as actions from '../../actions'
import { configurationLineItemSaga } from './configuration'
import {
  videoLineItemTypes,
  videoConfigurationItems,
  hdOutletsIds
} from '../../constants/configuration'
import {
  selectLineItemByType,
  selectIpnProgrammingLineItems,
  selectConfigItemByType
} from '../../selectors/configuration'
import {
  toggleIpnLineItems,
  getConfigItem,
  toggleAdditionalOutlets
} from '../../utils/configuration'
import { getVideoOfferView } from '../../services/cart'
import { selectConfiguredCart } from '../../selectors/cart'
import { parseCartSummary } from '../../utils/cart'
import { errors } from '../../constants/content'

const lineItemSagas = {
  [videoLineItemTypes.mDTA]: updateMDTASaga,
  [videoLineItemTypes.miniMDTA]: updateMDTASaga,
  [videoLineItemTypes.publicView]: updatePublicViewSaga
}

export function* configX1Saga({ payload }) {
  const { item, skipOutletValidation } = payload
  const {
    isLegacyVideoRestrictionEnabled,
    legacyVideoConfiguration = {}
  } = yield select(state => state.order)
  if (
    isLegacyVideoRestrictionEnabled &&
    !!Object.keys(legacyVideoConfiguration).length
  ) {
    yield put(
      actions.resetPrimaryOutletsLegacyVideo({ legacyVideoConfiguration, item })
    )

    // Disable Publicview checkbox when x1 is not selected
    const publicViewLineItem = yield select(
      selectLineItemByType(videoLineItemTypes.publicView)
    )
    yield put(actions.disableConfig(publicViewLineItem.type, !item.isSelected))

    if (item.isSelected) {
      // Disable X1 checkbox when checkbox is checked, use revert existing service button
      // to come back to original state
      const x1platformLineItem = yield select(
        selectLineItemByType(videoLineItemTypes.x1)
      )
      yield put(actions.disableConfig(x1platformLineItem.type, item.isSelected))
    }

    // Disable primaryOutlet dropdown always
    const primaryOutletLineItem = yield select(
      selectLineItemByType(videoLineItemTypes.primaryOutlet)
    )
    yield put(actions.disableConfig(primaryOutletLineItem.type, true))
  }

  const initialAdditionalOutlets = legacyVideoConfiguration?.TV_EQUIP_ADDITIONAL

  const ipnLineItems = yield select(selectIpnProgrammingLineItems)
  const additionalOutlets = yield select(
    selectLineItemByType(videoLineItemTypes.additionalOutlets)
  )

  yield put(actions.updateConfigItem({ item }))
  yield all(
    toggleIpnLineItems(ipnLineItems, item).map(updatedLineItem =>
      put(actions.setConfig(updatedLineItem))
    )
  )

  if (additionalOutlets && !skipOutletValidation) {
    yield put(
      actions.setConfig(
        toggleAdditionalOutlets(
          additionalOutlets,
          item.isSelected,
          isLegacyVideoRestrictionEnabled,
          initialAdditionalOutlets
        )
      )
    )
  }
}

export function* resetPrimaryOutletsLegacyVideoSaga({ payload }) {
  const { legacyVideoConfiguration, item } = payload
  const initialPrimaryOutlets =
    legacyVideoConfiguration?.PRIMARY_TV_EQUIP.configurationItems

  const selectedInitialPrimaryOutletId = Object.values(
    initialPrimaryOutlets
  ).find(i => i.isSelected)?.id

  if (!item.isSelected) {
    const primaryOutlet = yield select(
      selectLineItemByType(videoLineItemTypes.primaryOutlet)
    )

    yield put(
      actions.updateConfigItem({
        item: {
          ...primaryOutlet.configurationItems[selectedInitialPrimaryOutletId],
          isSelected: true,
          quantity: 1
        }
      })
    )
  }
}

export function* configX1UnsetIpnsSaga({ payload }) {
  const { lineItem, item: x1ConfigItem } = payload
  const ipnLineItems = yield select(selectIpnProgrammingLineItems)

  yield all(
    toggleIpnLineItems(ipnLineItems, x1ConfigItem).map(updatedLineItem =>
      put(actions.setConfig(updatedLineItem))
    )
  )

  if (lineItem?.type === videoLineItemTypes.x1) {
    yield put(actions.validateX1(payload))
  }
}

export function* configConvertEquipmentToX1Saga({ payload }) {
  const { item: x1ConfigItem } = payload

  if (x1ConfigItem.isSelected) {
    const primaryOutlet = yield select(
      selectLineItemByType(videoLineItemTypes.primaryOutlet)
    )

    yield put(
      actions.updateConfigItem({
        item: {
          ...primaryOutlet.configurationItems[
            videoConfigurationItems.additionalOutletStbHd
          ],
          isSelected: true,
          quantity: 1
        }
      })
    )
  }

  yield put(actions.configX1(x1ConfigItem))
}

export function* configTvEquipmentSaga({ payload }) {
  const { item } = payload
  const isNonHdItem = !hdOutletsIds.includes(item.id)
  const x1ConfigItem = yield select(
    selectConfigItemByType(videoLineItemTypes.x1)
  )

  yield put(actions.updateConfigItem(payload))

  if (isNonHdItem && x1ConfigItem?.isSelected) {
    yield put(
      actions.configX1(
        {
          ...x1ConfigItem,
          isSelected: false,
          quantity: 0
        },
        true
      )
    )
  }
}

export function* updateMDTASaga({ payload }) {
  const { item, lineItem } = payload
  const isMiniMDTA = lineItem.type === videoLineItemTypes.miniMDTA
  const oppositeMDTALineItem = yield select(
    selectLineItemByType(
      isMiniMDTA ? videoLineItemTypes.mDTA : videoLineItemTypes.miniMDTA
    )
  )
  const oppositeMDTAConfigItem = getConfigItem(oppositeMDTALineItem)

  yield put(
    actions.updateConfigItem({
      lineItem,
      item: {
        ...item,
        quantity: item.isSelected ? item.quantity : item.minLimit,
        cartQuantity: Number(item.isSelected)
      }
    })
  )

  // Only unselect if needed
  if (item.isSelected && oppositeMDTAConfigItem.isSelected) {
    yield put(
      actions.updateConfigItem({
        lineItem: oppositeMDTALineItem,
        item: {
          ...oppositeMDTAConfigItem,
          isSelected: false,
          quantity: oppositeMDTAConfigItem.minLimit
        }
      })
    )
  }

  yield put(actions.updateMDTAInstallFees(payload))
}

export function* updateMDTAInstallFeesSaga({ payload }) {
  const { item, lineItem } = payload
  const isMiniMDTA = lineItem.type === videoLineItemTypes.miniMDTA
  const miniMDTAInstall = yield select(
    selectLineItemByType(videoLineItemTypes.miniMDTAInstall)
  )
  const mDTAInstall = yield select(
    selectLineItemByType(videoLineItemTypes.mDTAInstall)
  )
  const miniMDTAInstallConfigItem = getConfigItem(miniMDTAInstall)
  const mDTAInstallConfigItem = getConfigItem(mDTAInstall)
  const isMiniMDTASelected = !!(item.quantity && isMiniMDTA)
  const isMDTASelected = !!(item.quantity && !isMiniMDTA)

  if (isMiniMDTASelected !== miniMDTAInstallConfigItem.isSelected) {
    yield put(
      actions.updateConfigItem({
        lineItem: miniMDTAInstall,
        item: {
          ...miniMDTAInstallConfigItem,
          quantity: Number(isMiniMDTASelected),
          isSelected: isMiniMDTASelected
        }
      })
    )
  }

  if (isMDTASelected !== mDTAInstallConfigItem.isSelected) {
    yield put(
      actions.updateConfigItem({
        lineItem: mDTAInstall,
        item: {
          ...mDTAInstallConfigItem,
          quantity: Number(isMDTASelected),
          isSelected: isMDTASelected
        }
      })
    )
  }
}

export function* updatePublicViewSaga({ payload }) {
  const { isSelected } = payload.item
  yield put(actions.updateConfigItem(payload))
  yield take(actions.UPDATE_CART_SUMMARY)

  try {
    const currentConfig = yield select(({ configuration }) => configuration)
    const configCart = yield select(selectConfiguredCart)
    const switchVideoOfferPayload = {
      isPublicViewSelected: isSelected,
      cart: configCart
    }

    yield put(actions.togglePageLoading())
    yield put(actions.setCartSummary(undefined))
    yield put(
      actions.replaceConfiguration({
        ...currentConfig,
        hideAllConfigurations: true
      })
    )

    const cart = yield call(getVideoOfferView, switchVideoOfferPayload)
    yield put(actions.setCartSummary(parseCartSummary(cart)))
    yield put(actions.setupConfiguration(cart))
  } catch (error) {
    if (error.message === errors.selApiFailureErrorMsg) {
      yield put(
        actions.setFormErrorAlert(errors.selApiFailurePopUp, {
          message: errors.selApiFailure,
          onOkAction: [actions.SEL_API_FAILURE]
        }))
    } else {
      throw error
    }
  }
  finally {
    yield put(actions.togglePageLoading(false))
  }
}

function* rootSaga() {
  yield takeLatest(
    actions.CONFIG_ITEM_TV,
    configurationLineItemSaga(lineItemSagas)
  )
  yield takeLatest(actions.CONFIG_TV_EQUIPMENT, configTvEquipmentSaga)
  yield takeLatest(actions.CONFIG_X1, configX1Saga)
  yield takeLatest(
    actions.CONFIG_CONVERT_EQUIP_TO_X1,
    configConvertEquipmentToX1Saga
  )
  yield takeLatest(actions.CONFIG_X1_UNSET_IPNS, configX1UnsetIpnsSaga)
  yield takeLatest(actions.UPDATE_MDTA_INSTALL_FEES, updateMDTAInstallFeesSaga)
  yield takeLatest(
    actions.RESET_PRIMARY_OUTLETS_LEGACY_VIDEO,
    resetPrimaryOutletsLegacyVideoSaga
  )
}

export default rootSaga
