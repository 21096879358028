import React from "react"

export const phoneAgreement = `
To recap our discussion, you are ordering [insert product and pricing]. You have agreed to an initial Service Term of [12/24/36 months],
which will be identified in the copy of your contract that I email to you. The initial Service Term begins on the date of installation/activation;
and pricing for the services excludes installation, taxes, surcharges, and fees. Canceling services after 30 days may result in an early termination
fee as outlined in our terms and conditions. Do you agree to these terms as I've described them? [Customer responds]. Great. Welcome to Comcast Business.
I'll be emailing you a confirmation that includes a copy of your contract and a link to the terms and conditions.
Please retain this for your records, and you do not need to sign and return the Service Order.
`

export const phoneAgreementSpanish = `
Para recapitular nuestra discusión, usted está pidiendo (producto de inserción y fijación de precios). Has aceptado un período de servicio inicial de [12/24/36],
que serán señalados en la copia de su contrato que le envié en un correo electrónico a usted. El Servicio Plazo inicial comienza en la fecha de instalación / activación;
y los precios de los servicios excluye instalación, impuestos, recargos y cargos.
Cancelación de los servicios después de 30 días puede resultar en un cargo de terminación temprano como se indica en nuestros términos y condiciones.
Bienvenido a Comcast Business. Estaré enviando un correo electrónico para su confirmación que incluye una copia de su contrato y un enlace a los términos y condiciones.
Por favor, guarde estos datos para sus registros, usted no tiene que firmar o devolver el Acuerdo de orden de servicio, pero usted tendrá que firmar y devolver el (llene requerida documento / s - LOA).
Usted va a recibir una llamada de parte de un representante de Comcast con respecto a su instalacion.
`

export const errors = {
  // TV
  mdtaSelection:
    'Please select mini mDTA, mDTA or change the Primary outlet selection',
  emptyPrimaryOutlet:
    'The additional outlets and Optional Programming will be removed. Do you want to continue?',
  additionalOutletSum:
    'TV outlets cannot exceed 16. Please reduce outlet quantity.',
  x1IncompatibleOutlet:
    'X1 is not compatible with this equipment selection. Please note, if any IPNs are selected, they will be removed since they require X1. Do you want to continue?',
  x1RequiresHdTvEquipment:
    'X1 requires TV Box + Remote HD. Do you want to change the equipment?',
  ipnX1Programming:
    'If you remove X1, your international channels will be removed too since they require X1 service. Do you want to continue?',
  videoPackageNotAdded: 'Please select a video package to continue',
  // Office
  smartOfficeCustomerOwnedEquipment:
    'Customer Owned Equipment is not compatible with SmartOffice. Remove SmartOffice from the quote or add compatible Internet equipment.',
  smartOfficeMinimumCameras:
    'Total number of cameras must be at least 1. Please update indoor or outdoor camera quantity.',
  smartOfficeMaximumCameras:
    'Total number of cameras cannot exceed 15. Please update indoor or outdoor camera quantity.',
  // Internet
  staticIpErrorJustification:
    'Please select IP justification reasons for each static IP on your quote. IP justification reasons are required for order submission.',
  securityEdgeCompatibility:
    'SecurityEdge is not compatible with the currently selected Equipment Type. In order to proceed, Equipment Type must be changed to Business Wireless Gateway, DOCSIS 3.1 Device, or Comcast Provided Equipment.',
  docsInitial: 'Please verify the required documents.',
  docsDecline:
    'Cannot proceed with Simplified Contracting if the customer disagrees with terms and conditions. Select another acceptance method to proceed.',
  docSave:
    'A technical error has occurred. Contract acceptance is not successful. Please retry.',
  invalidEmailForEdgeProduct:
    "Please provide a valid Primary Contact Email for a SecurityEdge order. The Primary Contact Email is required to activate the customer's services.",
  invalidCustomerEmail: 'Please provide a valid Primary Contact Email.',
  quote:
    'Unable to retrieve information at this time. Click Continue Order to view quote details on the Configuration page.',
  schedulingNotes:
    "Kindly remove any special characters like !,@,#,$,&,',~,!,^,` in Scheduling Notes. These characters are not acceptable in the downstream application.",
  verificationScript:
    'Please review order verification script and acknowledge before submitting your order.',
  addressConfirm:
    "Technical error has occurred. If you need to edit the customer's billing address details, please do so directly in Salesforce.",
  docDelete: 'Unable to delete Document. Please try again.',
  sikEligibility:
    'The add-on you selected is not eligible for a self-install kit and requires a professional installation. Your install type has changed.',
  // Voice Config Error
  respOrgAddresses:
    'RespOrg Address information is required for ported toll free orders.',
  loaAddresses: 'LOA Address is required for ported voice and CRCP orders.',
  loaName: 'LOA Account Name is required for ported voice and CRCP orders',
  displayNameRequired: 'DL Display Name is required',
  displayName: 'First letter of DL Display Name should be upper case.',
  displayNameRejectPeriod: 'DL Display name cannot contain periods (.)',
  displayNameLowerCase:
    'DL Display Name contains a word with all lower case letters',
  plaDisplayName:
    'PLA Display Name is mandatory when DL Display Name contains two or more upper case letters in the same word (e.g. CityHealth, CItyHealth) or if it contains numeric values, special characters, or spaces.',
  plaDisplayNameNumber: 'PLA Display Name cannot contain numbers.',
  headerText:
    'Please update DA/DL information using the DA/DL Header Lookup. Header Text, Header Code, and/or SIC Code are required fields',
  totalVoiceLines:
    'The sum of IMS voice lines should be less than or equal to 24.',
  voiceMailCount:
    'Number of voice mail boxes cannot exceed the number of voice lines',
  huntGroupVoiceMail:
    'Hunt Group and/or Voicemail selection has not been applied to a specific line. Please note that Hunt Group and Voicemail options are included in every VoiceEdge seat and does not need to be added to the order.',
  huntGroupQty:
    'Each hunt group requires a minimum of 2 or more IMS voice lines.',
  missingVoiceMail:
    'Number of Voicemail boxes on the Order does not match the number of Voicemail boxes configured.',
  ringToNumber: 'Please select a Ring to Number.',
  dlnCrcp:
    'The DLN cannot be a line with CRCP. The DLN must be associated to a Local Native or Local Ported Number.',
  dlnNotLocalPort:
    'The selected DLN is a CRCP number and cannot be locally ported. The DLN must be associated to a Local Native or Local Portal Number.',
  phoneNumber:
    'Please enter a valid telephone number (i.e. 10 digits, no special characters)',
  huntGroupSequence:
    'Correct hunt group sequencing is required. Only one telephone number can be assigned to a position. Each hunt group sequence requires two or more telephone numbers. Cannot skip a position in a hunt group sequence (ex: if three telephone numbers are in a sequence, selections must be P1, P2, P3).',
  callingArea: 'Please Select a Calling Area Destination',
  rateCenter:
    'Rate Center must have a minimum of 1 and maximum of 10 characters. Rate Center must be made of alpha-numeric characters.',
  missingCurrentProvider:
    'The "Current Provider" is required for Local Porting and CRCP. Please select the "Current Provider" that is associated with them.',
  e911Email:
    'Please enter a valid email for E911 Email Notification Address or select Opt Out.',
  voiceNotValidated:
    'You have modified your voice configurations. Please validate your voice configurations before proceeding.',
  crcpFailure:
    'CRCP validation has failed. Enter the Rate Center, NPA, NXX, and State details manually to proceed.',
  manualCrcpError:
    'Please provide the State, Rate Center, NPA and NXX when configuring CRCP for new or ported lines.',
  // Calendar Error
  preWireCddSameDay:
    'Prewire and installation cannot be on the same day. Please select an installation date',
  preWireAfterCdd:
    'Prewire cannot be after the installation date. Please select a prewire date',
  preWirePriorCdd: 'Please select a prewire date',
  lobFilterRemoval:
    'Your permissions do not allow you to remove a customer’s existing Line of Business.',
  schedulingEscalation:
    'Enter date, time, and escalation reason for Appointment Escalation before submitting the order.',
  modifyQuoteOfferInvalid:
    'This offer is no longer valid. If you need to change the configuration, you must select a new offer and any documentation associated to the order will be expired. The customer will need to accept the contract again.  If you want to maintain the existing configuration and contract, do not proceed with any changes. Are you sure you want to proceed with changes?',
  // Misc
  unauthorizedMessage:
    'It appears that you have an expired session. Please return to the opportunity page and try again.',
  closedLostMessage:() => (
    <>
      <span>
        The opportunity has been closed lost.
        <br />
        Quoting for closed lost opportunities is not applicable.
      </span>
    </>
  ),
  fatalError:
    'A fatal error has occurred. Please refresh the page and try again. If issue persists, route to Bundle Builder to complete Order.',
  documentUpload:
    'Failure in uploading documents. Please try to upload the document again. If issue persists, route to Bundle Builder to complete the Order.',
  abtMessage:
    'Unable to auto-create address in ABT; your order can still be processed through Frictionless with partial automation.',
  videoDwellType:
    'Dwell Type does not match Video Package being sold. Would you like to continue?',
  // moves
  configMoveStatusMessage:
    'Please select a Move Status for the following LOB`s:',
  configtempOverLapDateMessage:
    'Please select a Temp. Overlap Date for the following LOB`s:',
  missingVoiceLineMoveStatus:
    'Please select a Valid Move Status for all Voice and Toll Free Lines',
  flLaunchErrorMessage:
    'An unexpected technical error has occurred. Please relaunch the quote process and try again.',
  fdxConfigRevertError:
    'You have selected an equipment configuration that will affect the customer`s upload speed. Do you wish to proceed?',
  totalVoiceLinesForEPONError:
    'Total voice lines for EPON is restricted to a maximum of 8 lines.',
  selApiFailure: 'Video products cannot be quoted at this time.  Please either continue this quote without Video, or try again later.',
  selApiFailureErrorMsg: 'BILLING_SERVICE_FAILURE',
  selApiFailurePopUp: 'selApiFailure'
}

export const success = {
  docsVerifyByPhone:
    'The documents are being created and can be accessed in the Document Management section on the Opportunity. Please click on Save and Continue to proceed with document validation.',
  sendProposal: 'An email has been sent to the customer for eSignature',
  abtMessage: 'The address has been successfully created in ABT.'
}

export const warning = {
  smartofficeLicense:
    'The customer already has SmartOffice, and you do not have permissions to sell or make changes to their SmartOffice service. If they are not requesting to remove their SmartOffice service, lead pass the customer to an agent that has the correct permissions.',
  genesisCustomerModem:
    'Next Gen upload speeds are not currently supported on Customer Owned devices. Customers will receive the regular upload speeds subject to their device capabilities. Please refer to the Comcast Business customer owned internet device capabilities website for information on Customer Owned devices.',
  staticMoveConfig:
    "Customer cannot move their Static IP's to their new location due to this being a cross-region move. Customer can still have Static IP's at their new location, but will be assigned new Static IP's at install"
}

export const rateCenterMessages = {
  nextButton:
    'Click the Next button below to populate Rate Center, State, and NPA dropdown values'
}

export const previousInstallation =
  'Installation was previously scheduled for: '
export const previousPrewire = 'Prewire was previously scheduled for: '

export const revertExistingConfig =
  'You are about to revert the Configuration back to the customer`s current services. Are you sure you want to proceed?'

export const appTitle = 'Existing Customer'

export const peoDocAcceptanceMessage =
  'Existing or pending documentation associated to the order will be expired. Customer will need to accept the updated contract again. Would you like to proceed with changes?'

export const partialAutomation = {
  title: 'PARTIAL AUTOMATION',
  reminder:
    'The date / timeslot selected below is not guaranteed until the BSC OAs the order.'
}

export const fullAutomation = {
  title: 'FULL AUTOMATION',
  reminder:
    'Once submitted, the customer will be scheduled in the biller. This appointment is guaranteed upon successful order submission.'
}

export const noOffersMessage =
  'Please retry or select different criteria. If the offer you are looking for is still not available through your filter/selection above, please proceed with Bundle Builder.'

export const noOffersVideoBoltOnMessage =
  'If you are looking for an offer that includes Video services and do not see it, please select the non-Video product(s) you want and proceed to next screen to add your preferred Video tier through Video Bolt On. If the offer you are looking for is still not available through your filter/selection above, or through the use of Video Bolt On, please proceed with Bundle Builder.'
export const videoBoltOnEligible = 'Video Bolt On Eligible'
export const smartOfficeBoltOnEligible = 'Smart Office Bolt On Eligible'

export const legacyEquipmentMessage =
  'This customer has legacy equipment. If the customer wants to keep their legacy equipment, go to Bundle Builder using the Route to Bundle Builder hyperlink.'

export const legacyFullFeaturedLinesMessage =
  'Full Featured lines are no longer offered as part of re-contracting. Please migrate the customer to either Mobility or Basic lines'

export const cbmStandaloneConfirmationMessage =
  'This order contains only Comcast Business Mobile service which should be processed through XM360.'
